<template>
    <div class="elevenlabs-widget">
        <elevenlabs-convai agent-id="nAOoG8MikFMqeTaL6EzK"></elevenlabs-convai>
    </div>
</template>

<script setup>
onMounted(() => {
    const script = document.createElement('script')
    script.src = 'https://elevenlabs.io/convai-widget/index.js'
    script.async = true
    document.body.appendChild(script)
})
</script>

<style scoped>
.elevenlabs-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}
</style>
