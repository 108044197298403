export function useAutoPageviews(plausible, initOptions) {
  const options = { ...initOptions };
  function setEventOptions(newOptions) {
    Object.assign(options, newOptions);
  }
  function page() {
    plausible.trackPageview(options);
  }
  const originalPushState = history.pushState;
  function install() {
    if (originalPushState) {
      history.pushState = function(...args) {
        originalPushState.apply(this, args);
        page();
      };
      window.addEventListener("popstate", page);
    }
    if (plausible.options.hashMode)
      window.addEventListener("hashchange", page);
    page();
  }
  function cleanup() {
    if (originalPushState) {
      history.pushState = originalPushState;
      window.removeEventListener("popstate", page);
    }
    if (plausible.options.hashMode)
      window.removeEventListener("hashchange", page);
  }
  return {
    install,
    cleanup,
    setEventOptions
  };
}
