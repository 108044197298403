<script setup lang="ts">
const { data: page } = await useAsyncData('index', () => queryContent('/').findOne())
const { showModal, handleRedirect } = useAppRedirect()

// Ajout des variables pour contrôler la visibilité des widgets
const showElevenLabs = ref(false)

// Fonction pour gérer les clics sur les liens
const handleHeroLinkClick = (link: any) => {
  handleRedirect()
}

useSeoMeta({
  title: page.value.title,
  ogTitle: page.value.title,
  description: page.value.description,
  ogDescription: page.value.description
})
</script>

<template>
  <div>
    <SeoContent />
    <ULandingHero :title="page.hero.title" :description="page.hero.description" orientation="horizontal" :links="page.hero.links.map(link => ({
      ...link,
      click: (e) => {
        e.preventDefault()
        handleHeroLinkClick(link)
      }
    }))" class="overflow-visible">
      <template #headline>
        <UBadge v-if="page.hero.headline" variant="subtle" size="lg" class="relative rounded-full font-semibold">
          <NuxtLink :to="page.hero.headline.to" class="focus:outline-none" tabindex="-1">
            <span class="absolute inset-0" aria-hidden="true" />
          </NuxtLink>
          {{ page.hero.headline.label }}
          <UIcon v-if="page.hero.headline.icon" :name="page.hero.headline.icon"
            class="ml-1 w-4 h-4 pointer-events-none" />
        </UBadge>
      </template>

      <template #default>
        <div
          class="relative w-full aspect-[16/12] md:aspect-[16/11] lg:aspect-[16/10] xl:aspect-[16/9] rounded-xl overflow-hidden shadow-lg">
          <iframe
            src="https://www.youtube.com/embed/mHxLERO7dqs?autoplay=1&mute=1&loop=1&playlist=mHxLERO7dqs&controls=0&modestbranding=1&showinfo=0"
            class="w-full h-full rounded-md shadow-xl ring-1 ring-gray-300 dark:ring-gray-700"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen title="YouTube video player">
          </iframe>
        </div>
      </template>
    </ULandingHero>

    <ULandingSection :title="page.problem.title" :description="page.problem.description"
      :headline="page.problem.headline" class="bg-gray-50 dark:bg-gray-900">
      <ULandingGrid id="problem" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <ULandingCard v-for="(item, index) in page.problem.items" :key="index" v-bind="item"
          class="col-span-6 row-span-3" />
      </ULandingGrid>
    </ULandingSection>

    <ULandingSection :title="page.features.title" :description="page.features.description"
      :headline="page.features.headline">
      <ULandingGrid id="features" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <ULandingCard v-for="(item, index) in page.features.items" :key="index" v-bind="item"
          class="col-span-4 row-span-3" />
      </ULandingGrid>
    </ULandingSection>

    <ULandingSection :title="page.pricing.title" :description="page.pricing.description"
      :headline="page.pricing.headline">
      <UPricingGrid id="pricing" :compact="false" :ui="{
        wrapper: 'grid grid-cols-1 lg:grid-cols-2 w-full justify-center items-center gap-4 sm:gap-8'
      }" class="scroll-mt-[calc(var(--header-height)+140px+128px+96px)] max-w-6xl mx-auto px-4 sm:px-6">
        <UPricingCard v-for="(plan, index) in page.pricing.plans" :key="index" v-bind="plan" :button="{
          ...plan.button,
          click: (e) => {
            e.preventDefault()
            handleHeroLinkClick(plan.button)
          }
        }" class="w-full" />
      </UPricingGrid>
    </ULandingSection>

    <!-- <ULandingSection :headline="page.testimonials.headline" :title="page.testimonials.title"
      :description="page.testimonials.description">
      <UPageColumns id="testimonials" class="xl:columns-4 scroll-mt-[calc(var(--header-height)+140px+128px+96px)]">
        <div v-for="(testimonial, index) in page.testimonials.items" :key="index" class="break-inside-avoid">
          <ULandingTestimonial v-bind="testimonial" />
        </div>
      </UPageColumns>
    </ULandingSection> -->

    <ULandingSection class="bg-primary-50 dark:bg-primary-400 dark:bg-opacity-10">
      <ULandingCTA v-bind="{
        ...page.cta,
        links: page.cta.links.map(link => ({
          ...link,
          click: (e) => {
            e.preventDefault()
            handleHeroLinkClick(link)
          }
        }))
      }" :card="false" />
    </ULandingSection>

    <ULandingSection id="faq" :title="page.faq.title" :description="page.faq.description"
      class="scroll-mt-[var(--header-height)]">
      <ULandingFAQ multiple :items="page.faq.items" :ui="{
        button: {
          label: 'font-semibold',
          trailingIcon: {
            base: 'w-6 h-6'
          }
        }
      }" class="max-w-4xl mx-auto" />
    </ULandingSection>

    <NotifyMeModal v-model="showModal" />

    <ClientOnly>
      <ElevenLabsWidget v-if="showElevenLabs" />
    </ClientOnly>
  </div>
</template>
