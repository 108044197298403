<template>
    <div class="botpress-widget">
        <!-- Le widget sera injecté automatiquement par Botpress -->
    </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'

onMounted(() => {
    // Configuration personnalisée du widget
    window.botpressWebChat = {
        cssCommon: `
            .bpw-floating-button {
                width: 60px !important;
                height: 60px !important;
            }
            .bpw-floating-button img {
                width: 35px !important;
                height: 35px !important;
            }
        `
    }

    // Injection du script principal Botpress
    const mainScript = document.createElement('script')
    mainScript.src = 'https://cdn.botpress.cloud/webchat/v2.2/inject.js'
    document.body.appendChild(mainScript)

    // Injection du script de configuration spécifique
    mainScript.onload = () => {
        const configScript = document.createElement('script')
        configScript.src = 'https://files.bpcontent.cloud/2025/01/07/11/20250107115331-GB9Z29N5.js'
        document.body.appendChild(configScript)
    }
})

onUnmounted(() => {
    // Nettoyage des scripts si nécessaire
    const scripts = document.querySelectorAll('script[src*="botpress"]')
    scripts.forEach(script => script.remove())
})
</script>

<style scoped>
.botpress-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}
</style>
