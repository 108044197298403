function isFile(protocol) {
  return protocol === "file:";
}
function isIgnored(hostname, ignoredHostnames, ignoreSubDomains) {
  if (ignoreSubDomains)
    return ignoredHostnames.some((ignoredHostname) => hostname === ignoredHostname || hostname.endsWith(`.${ignoredHostname}`));
  return ignoredHostnames.includes(hostname);
}
function isUserSelfExcluded() {
  try {
    return localStorage.getItem("plausible_ignore") === "true";
  } catch (_) {
    return false;
  }
}
function createEventData(data = {}) {
  const { url, referrer, deviceWidth } = data;
  return {
    url: url ?? window.location.href,
    referrer: referrer ?? document.referrer,
    deviceWidth: deviceWidth ?? window.innerWidth
  };
}
function sendEvent(apiHost, payload, callback) {
  return fetch(`${apiHost}/api/event`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain"
    },
    body: JSON.stringify(payload)
  }).then((response) => {
    callback?.({ status: response.status });
  }).catch(() => {
  });
}

function createPayload(eventName, plausibleOptions, data, options) {
  const payload = {
    n: eventName,
    u: data.url,
    d: plausibleOptions.domain,
    r: data.referrer,
    w: data.deviceWidth,
    h: plausibleOptions.hashMode ? 1 : 0,
    p: options && options.props ? JSON.stringify(options.props) : void 0,
    $: options && options.revenue ? options.revenue : void 0
  };
  return payload;
}

function createPlausibleTracker(initOptions) {
  const protocol = window.location.protocol;
  const defaultOptions = {
    enabled: true,
    hashMode: false,
    domain: window.location.hostname,
    apiHost: "https://plausible.io",
    ignoredHostnames: ["localhost"],
    ignoreSubDomains: false,
    logIgnored: false
  };
  const plausibleOptions = { ...defaultOptions, ...initOptions };
  const sendEvent$1 = (payload, callback) => sendEvent(plausibleOptions.apiHost, payload, callback);
  function trackEvent(eventName, options) {
    if (!plausibleOptions.enabled)
      return;
    const data = createEventData(options?.data);
    const payload = createPayload(eventName, plausibleOptions, data, options);
    if (isFile(protocol) || isIgnored(plausibleOptions.domain, plausibleOptions.ignoredHostnames, plausibleOptions.ignoreSubDomains) || isUserSelfExcluded()) {
      if (plausibleOptions.logIgnored)
        console.info(`[Plausible] ${eventName}`, payload);
      options?.callback?.({ status: null });
    } else {
      return sendEvent$1(payload, options?.callback);
    }
  }
  function trackPageview(options) {
    return trackEvent("pageview", options);
  }
  window.plausible = trackEvent;
  return {
    trackEvent,
    trackPageview,
    options: plausibleOptions
  };
}

export { createPlausibleTracker };
